import { render } from "./Home.vue?vue&type=template&id=42051cb8"
import script from "./Home.vue?vue&type=script&lang=js"
export * from "./Home.vue?vue&type=script&lang=js"

import "./Home.vue?vue&type=style&index=0&id=42051cb8&lang=scss"
script.render = render

export default script
import QInput from 'quasar/src/components/input/QInput.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QTree from 'quasar/src/components/tree/QTree.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInput,QSeparator,QTree,QBtn});
