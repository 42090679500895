<template>
  <div class="column items-center bg-grey-3" style="height: 100vh;">
    <div class="home bg-white " style="min-width: 320px; min-height: 100vh;">
      <q-input
        class="q-pa-md"
        outlined
        v-model="links.filter"
        label="search"
      ></q-input>
      <q-separator></q-separator>
      <q-tree
        class="q-pa-md"
        :nodes="links.data"
        node-key="label"
        :filter="links.filter"
        default-expand-all
      >
        <template v-slot:header-generic="prop">
          <div class="row items-center">
            <q-btn
              type="a"
              flat
              :href="prop.node.url"
              target="_blank"
              :label="prop.node.label"
              @click.stop=""
              color="primary"
              dense
              icon="text_snippet"
            ></q-btn>
          </div>
        </template>
      </q-tree>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";
export default {
  setup() {
    let links = reactive({
      filter: "",
      data: [
        {
          label: "Link Panduan Penggunaan",
          header: "root",
          children: [
            {
              label: "Akademik",
              children: [
                {
                  label: "Wali Kelas",
                  header: "generic",

                  url:
                    "https://docs.google.com/document/d/1W8h34_WIdbXLT2wnUXEk3PYu3o4ipt7k/edit?usp=sharing&ouid=107362680045391019212&rtpof=true&sd=true",
                },
                {
                  label: "Guru Mapel",
                  header: "generic",

                  url:
                    "https://docs.google.com/document/d/1qVgvMq7UIHlSUdmKoN8rIJFANYyMKYNn/edit?usp=sharing&ouid=107362680045391019212&rtpof=true&sd=true",
                },
                {
                  label: "Staff Input",
                  header: "generic",

                  url:
                    "https://docs.google.com/document/d/1O9UiMWe5ThZQiiMTXnvfmrAkWTSksELB/edit?usp=sharing&ouid=107362680045391019212&rtpof=true&sd=true",
                },
              ],
            },
            {
              label: "Pengasuhan",
              children: [
                {
                  label: "Musyrif",
                  header: "generic",

                  url:
                    "https://docs.google.com/document/d/120jTCMWV6-ZCSeRJm8FrdloscnbaALpw/edit?usp=sharing&ouid=107362680045391019212&rtpof=true&sd=true",
                },
                {
                  label: "Staff Input",
                  header: "generic",

                  url:
                    "https://docs.google.com/document/d/1hrrSuJc_kQImF6pl0AabfeZDhk-fCMho/edit?usp=sharing&ouid=107362680045391019212&rtpof=true&sd=true",
                },
              ],
            },
          ],
        },
      ],
    });

    return {
      links,
    };
  },
};
</script>

<style lang="scss">
.home {
}
</style>
